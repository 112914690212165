h1 {
    font: 20px Luminari, fantasy;
    padding-left: 30px;
    padding-top: 10px;
}

td {
    font: 14px "Century Gothic", Futura, sans-serif;
    padding-left: 30px;
}

input {
    margin-right: 5px;
    margin-left: 5px;
}